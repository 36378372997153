<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	WithLeftMenu from '@/components/app/WithLeftMenu'

	import 	projectsData from "@/assets/json/projects.json";
	import 	featuresData from "@/assets/json/features.json";

	export default {
		metaInfo: {
			title: 'Разработка корпоративного сайта в Казани под ключ |  «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Закажите разработку корпоративного сайта с умным дизайном! Этапы создания, стоимость разработки и гарантии. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Разработка корпоративного сайта в Казани под ключ |  «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Закажите разработку корпоративного сайта с умным дизайном! Этапы создания, стоимость разработки и гарантии. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Наши проекты',
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					showPagination: false,
					data: projectsData,
					category: "Корпоративный сайт"
				},{
					component: 'dropDown',
					type: '2',
					name: 'Этапы создания корпоративного сайта',
					showHideButton: false,
					data: [
						{
							"title": "Бизнес аналитика",
							"desc": "Срок: ~ 6 дней",
							"content": "<ul> <li><span class='listLine'>—</span> Брифинг заказчика. Определение целей и задач</li> <li><span class='listLine'>—</span> Анализ рынка: изучение конкурентов и целевой аудитории.</li> <li><span class='listLine'>—</span> Выявление УТП</li> <li><span class='listLine'>—</span> Разработка детального плана работ</li> </ul>"
						},{
							"title": "Проектирование и дизайн",
							"desc": "Срок: ~ 20 дней",
							"content": "<ul> <li><span class='listLine'>—</span> Формирование структуры и кликабельных прототипов.</li> <li><span class='listLine'>—</span> Подбор цвета и стилистики.</li> <li><span class='listLine'>—</span> Разработка дизайн-макетов в трех разрешениях: десктоп, планшет, мобильник.</li> </ul>"
						},{
							"title": "Верстка и программирование",
							"desc": "Срок: ~ 30 дней",
							"content": "<ul> <li><span class='listLine'>—</span> Адаптивная верстка дизайна. Одинаковое отображение во всех браузерах и устройствах</li> <li><span class='listLine'>—</span> Настройка CMS Bitrix.</li> <li><span class='listLine'>—</span> Программирование функционала.</li> </ul>"
						},{
							"title": "Контентное наполнение",
							"desc": "Срок: ~ 4 дня",
							"content": "<ul> <li><span class='listLine'>—</span> Создание и размещение текста для всех страниц.</li> <li><span class='listLine'>—</span> Редактура существующего текста.</li> <li><span class='listLine'>—</span> Подбор и размещение оптимизированных картинок</li> </ul>"
						},{
							"title": "Базовая SEO-оптимизация",
							"desc": "Срок: ~ 2 дня",
							"content": "<ul> <li><span class='listLine'>—</span> Техническая оптимизация сайта для корректной индексации поисковыми системами</li> <li><span class='listLine'>—</span> Настройка мета тегов и заголовков.</li> </ul>"
						},{
							"title": "Запуск и тестирование",
							"desc": "Срок: ~ 2 дня",
							"content": "<ul> <li><span class='listLine'>—</span> Проверка сайта перед выводом по чек-листу</li> <li><span class='listLine'>—</span> Перенос на хостинг.</li> <li><span class='listLine'>—</span> Привязка домена.</li> </ul>"
						}
					]
				},{
					component: 'slider',
					name: 'Стоимость и гарантии',
					contentType: 'infoBlocks2',
					data: [
						{
							value: '300000',
							label: '500-600 т.р.',
							firstTitle: 'Бюджет: 500 – 600 т.р.',
							firstText: 'Корпоративный сайт с уникальным дизайном, где детально проработаны основные страницы: главная, услуги, о компании, прайс, новости, акции, контакты, 3-4 второстепенных раздела.<br><br> Подходит небольшим компаниям.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный сайт. Дальнейшее сопровождение и доработки по цене 1 800 руб. за норма-час.'
						},{
							value: '500000',
							label: '600-800 т.р.',
							firstTitle: 'Бюджет: 600 – 800 т.р.',
							firstText: 'Корпоративный сайт с каталогом товаров или услуг. Детально продуманные и отрисованные внутренние страницы продукта. Большее количество страниц на сайте:  главная, услуги, о компании, прайс, новости, акции, статьи, руководство, контакты, 5-10 второстепенных разделов.<br><br> Подходит среднему бизнесу или производству.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный сайт. Дальнейшее сопровождение и доработки по цене 1 800 руб. за норма-час.'
						},{
							value: '800000',
							label: '800 т.р. – 1.2 млн.р.',
							firstTitle: 'Бюджет: 800 т.р. – 1.2 млн.р.',
							firstText: 'Крупный корпоративный сайт/портал с калькуляторами, графиками, личными кабинетами. Помимо основных страниц, прорабатывается каждая услуга или продукция (от 15 до 30 оформленных в виде лендингов внутренних страниц).<br><br> Подходит крупному бизнесу.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный сайт. Дальнейшее сопровождение и доработки по цене 1 800 руб. за норма-час.'
						},{
							value: '1000000',
							label: '1.2 млн.р. и выше',
							firstTitle: 'Бюджет: 1.2 млн.р. и выше',
							firstText: 'Высоконагруженный проект. Интеграция с внутренними информационными системами, двусторонний обмен. Личные кабинеты администратора/клиента. 50+ вложенных страниц сайта.<br><br> Подходит компаниям, со сложными бизнес-задачами.',
							secondTitle: 'Гарантии',
							secondText: 'Бессрочная гарантия на созданный сайт. Дальнейшее сопровождение и доработки по цене 1 800 руб. за норма-час.'
						}
					]
				},{
					component: 'aboutUs',
					name: 'Наш выбор CMS',
					data: [
						{
							component: 'image',
							image: require(`@/assets/img/cms.jpg`),
							additionalClass: 'margin7'
						},{
							component: 'text',
							text: `1С-Битрикс — система управления сайтом №1 в России <a href="https://ratingruneta.ru/cms/" target="_blank" rel="nofollow" class="redText">согласно рейтингу Рунета</a>. CMS позволяет разработчикам создавать функционал любой сложности, а владельцам — управлять проектом без помощи программистов.<br><br> С 2017 года сохраняем статус <a href="https://www.1c-bitrix.ru/partners/570583.php" target="_blank" rel="nofollow" class="redText">Золотого сертифицированного партнера</a>. Это значит, что разработчики работают по стандартам Битрикса - страницы сайтов загружаются быстро, отсутствуют баги. Перед сдачей заказчику, проект проверяется <a href="https://dev.1c-bitrix.ru/learning/course/index.php?COURSE_ID=43&CHAPTER_ID=04830" target="_blank" rel="nofollow" class="redText">монитором качества</a>.`,
						}
					]
				},{
					component: 'features',
					name: 'Особенности создания сайтов в Артрокетс',
					data: featuresData
				},{
					component: 'advantages',
					name: 'После сдачи проекта',
					columns: 2,
					data: [
						{
							title: 'Поисковое продвижение',
							text: 'Увеличиваем ежемесячную посещаемость ресурса и улучшаем конверсию.',
						},{
							title: 'Техническая поддержка',
							text: 'Дорабатываем и сопровождаем проекты в режиме 24/7.',
							link: '/uslugi/support/'
						}
					]
				}
			]
		}),
		name: 'internet-magazin',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu
		}
	}
</script>
